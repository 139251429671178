import { useState } from 'react';

import { useDebounce } from '@/hooks/useDebounce';

export default function useHighlightedSlug() {
  const [highlightedSlug, setHighlightedSlug] = useState<string>();

  const onMouseEnter = useDebounce((slug: string) => {
    setHighlightedSlug(slug);
  }, 10);

  const onMouseLeave = useDebounce(() => {
    setHighlightedSlug(undefined);
  }, 10);

  return {
    highlightedSlug,
    onMouseEnter,
    onMouseLeave,
  };
}
