import clsx from 'clsx';

type SearchMapWrapperProps = {
  isFullScreenMap?: boolean;
  withMap?: boolean;
} & React.ComponentPropsWithoutRef<'div'>;

export default function SearchMapWrapper({
  className,
  children,
  isFullScreenMap,
  withMap,
}: SearchMapWrapperProps) {
  return (
    <div
      className={clsx(
        'relative w-full lg:h-full lg:w-[43%]',
        isFullScreenMap ? 'flex-1' : 'max-lg:hidden',
        !withMap && 'hidden',
        className
      )}
    >
      {children}
    </div>
  );
}
