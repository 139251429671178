import { forwardRef } from 'react';

import StaticMap from './StaticMap';
import { CameraMapOptions } from './types';
type SearchStaticMapProps = {
  coords: CameraMapOptions;
};

const SearchStaticMap = forwardRef<HTMLDivElement, SearchStaticMapProps>(
  ({ coords }, ref) => {
    return (
      <div ref={ref} className="absolute inset-0">
        <StaticMap sizes="(max-width: 1140px) 43.01vw, 100vw" coords={coords} />
      </div>
    );
  }
);

SearchStaticMap.displayName = 'SearchStaticMap';

export default SearchStaticMap;
