'use client';

import Image, { ImageLoaderProps } from 'next/image';

import { LatLng } from '@/models/LatLng';
import { getServerConfig } from '@/utils/config';
import { MAP_STYLE } from '@/utils/constants';

import { CameraMapOptions } from './types';

import clsx from 'clsx';

type StaticMapProps = {
  coords: CameraMapOptions;
  withMarker?: boolean;
  className?: string;
  width?: number;
  height?: number;
  sizes?: string;
  scaleMultiplier?: boolean;
};

const MAX_SIZE_MAPBOX_STATIC_API = 1280;
const DEFAULT_WIDTH = 1080;
const DEFAULT_HEIGHT = 1000;

const mapboxImageLoader = ({
  src,
  width,
  height,
  mapboxAccessToken,
  scaleMultiplier,
}: ImageLoaderProps & {
  mapboxAccessToken: string;
  height: number;
  scaleMultiplier?: boolean;
}) => {
  const maxWidth =
    width > MAX_SIZE_MAPBOX_STATIC_API ? MAX_SIZE_MAPBOX_STATIC_API : width;
  const maxHeight =
    height > MAX_SIZE_MAPBOX_STATIC_API ? MAX_SIZE_MAPBOX_STATIC_API : height;
  const multiplier = scaleMultiplier ? `@2x` : '';
  return `${src}/${maxWidth}x${maxHeight}${multiplier}?access_token=${mapboxAccessToken}&logo=false`;
};

export default function StaticMap({
  coords,
  withMarker,
  className,
  sizes,
  scaleMultiplier,
  width = DEFAULT_WIDTH,
  height = DEFAULT_HEIGHT,
}: StaticMapProps) {
  const { NEXT_PUBLIC_MAPBOX_STATIC_ACCESS_TOKEN } = getServerConfig();

  const zoom = coords.zoom;
  const { lat: latitude, lng: longitude } = coords.center as LatLng;

  return (
    <div className="relative w-full h-full">
      <Image
        className={clsx('object-cover object-center w-full h-full', className)}
        loader={(props) =>
          mapboxImageLoader({
            ...props,
            height,
            scaleMultiplier,
            mapboxAccessToken: NEXT_PUBLIC_MAPBOX_STATIC_ACCESS_TOKEN,
          })
        }
        src={`https://api.mapbox.com/styles/v1/mapbox/${MAP_STYLE}/static/${longitude},${latitude},${zoom}`}
        width={width}
        height={height}
        priority
        alt={`Static Map around the location ${longitude}-${latitude} with zoom ${zoom}`}
        sizes={sizes || `${width}px`}
      />
      {withMarker && (
        <div
          className={clsx(
            'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2',
            'block w-4 h-4 rounded-full bg-teal-5'
          )}
        />
      )}
    </div>
  );
}
