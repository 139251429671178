import { Feature, Geometry } from 'geojson';
import mapboxgl from 'mapbox-gl';
import { useSearchParams } from 'next/navigation';
import { MutableRefObject, useEffect, useRef } from 'react';

import { OnMoveEndProps, OnZoomChangeProps, useMap } from '@/hooks/useMap';
import { ScreenSize, useScreenSize } from '@/hooks/useScreenSize';

import { Bounds, CameraMapOptions, MapItem } from './types';

type MapProps<T extends MapItem> = {
  initialCameraOptions: CameraMapOptions;
  cameraOptions?: CameraMapOptions;
  features: Feature<Geometry, T>[];
  mapboxAccessToken: string;
  highlightedSlug?: string | undefined;
  isFullScreenMap?: boolean;
  minZoom?: number;
  maxZoom?: number;
  clusterPerLocation?: boolean;
  staticMapRef: MutableRefObject<HTMLDivElement | null>;
  onMarkerClicked?: (marker: T | T[] | undefined) => void;
  onMapRefChange?: (mapRef: MutableRefObject<mapboxgl.Map | null>) => void;
  onZoomChange?: (props: OnZoomChangeProps) => void;
  onMoveEnd?: (props: OnMoveEndProps) => void;
  onIdle?: (bounds: Bounds) => void;
};

export default function Map<T extends MapItem>({
  initialCameraOptions,
  cameraOptions,
  features,
  mapboxAccessToken,
  highlightedSlug,
  isFullScreenMap,
  minZoom,
  maxZoom,
  clusterPerLocation,
  staticMapRef,
  onMarkerClicked,
  onMapRefChange,
  onZoomChange,
  onMoveEnd,
  onIdle,
}: MapProps<T>) {
  const map = useRef<mapboxgl.Map | null>(null);
  const mapContainer = useRef(null);
  const screenSize = useScreenSize();

  const searchParams = useSearchParams();
  const haveParamsWithCoords =
    searchParams?.get('bounds') != null || searchParams?.get('center') != null;

  const { animationDuration, initializeMap, highlightPointOnMap } = useMap({
    haveParamsWithCoords,
    initialCameraOptions,
    features,
    map,
    mapboxAccessToken,
    mapContainer,
    staticMapRef,
    minZoom,
    maxZoom,
    clusterPerLocation,
    onMarkerClicked,
    onZoomChange,
    onMoveEnd,
    onIdle,
  });
  const isMapLoaded = map.current?.isStyleLoaded();

  useEffect(() => {
    if (isFullScreenMap) {
      map?.current?.resize({ shouldNotUpdateQueryParams: true });
    }
  }, [isFullScreenMap]);

  useEffect(() => {
    if (map?.current) return;
    initializeMap();

    return () => {
      map.current?.remove();
    };
  }, []);

  useEffect(() => {
    if (!haveParamsWithCoords && map?.current) {
      map.current.jumpTo({ center: initialCameraOptions.center });
    }
  }, [initialCameraOptions.center]);

  useEffect(() => {
    if (!cameraOptions || !map?.current || !searchParams?.get('query')) return;

    if (cameraOptions.bounds) {
      map.current.fitBounds(
        cameraOptions.bounds,
        {
          duration: animationDuration,
          ...cameraOptions.boundsOptions,
        },
        { shouldNotUpdateQueryParams: true }
      );
    } else {
      map.current.easeTo(
        {
          center: cameraOptions.center,
          zoom: cameraOptions.zoom,
        },
        { shouldNotUpdateQueryParams: true }
      );
    }
  }, [map, cameraOptions, searchParams?.get('query')]);

  useEffect(() => {
    if (screenSize >= ScreenSize.SM && isMapLoaded) {
      highlightPointOnMap(highlightedSlug);
    }
  }, [highlightedSlug, isMapLoaded]);

  useEffect(() => {
    if (onMapRefChange) {
      onMapRefChange(map);
    }
  }, [map]);

  return <div ref={mapContainer} className="w-full h-full map-container" />;
}
